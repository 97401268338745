
import {message} from "../webix/message";

(function(){
	var key = "VGhpcyBhcHAgdXNlcyBhIHRyaWFsIHZlcnNpb24gb2YgV2ViaXggVUkuIFRoZSB0cmlhbCBwZXJpb2QgaGFzIGV4cGlyZWQuIDxicj4gSWYgeW91ciB3YW50IHRvIGNvbnRpbnVlIHVzaW5nIFdlYml4IFVJLCBwbGVhc2UgdXBncmFkZSB0byBDb21tZXJjaWFsIG9yIEdQTCBlZGl0aW9uLg==";
	if (window.atob && (new Date()) > (new Date(2099,0,1)))
		setTimeout(function(){
			message({ type:"error", expire:-1, text:atob(key) });
		}, Math.floor(Math.random()*5000) + 1000);
})();
		